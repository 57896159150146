// extracted by mini-css-extract-plugin
export var badge = "product-showcase-module--badge--2e202";
export var card = "product-showcase-module--card--ce2d0";
export var cardInner = "product-showcase-module--cardInner--362d7";
export var cardWrapper = "product-showcase-module--cardWrapper--6dfb2";
export var cardWrapperStack = "product-showcase-module--cardWrapperStack--e14c9";
export var containerWrapper = "product-showcase-module--containerWrapper--74464";
export var cta = "product-showcase-module--cta--f63d1";
export var ctaContainer = "product-showcase-module--ctaContainer--dfe77";
export var description = "product-showcase-module--description--458b2";
export var descriptionContent = "product-showcase-module--descriptionContent--b9656";
export var descriptionContentContainer = "product-showcase-module--descriptionContentContainer--240a9";
export var descriptionCopy = "product-showcase-module--descriptionCopy--28fcb";
export var headline = "product-showcase-module--headline--ff94b";
export var headlineContentContainer = "product-showcase-module--headlineContentContainer--bf911";
export var headlineContentContainerHidden = "product-showcase-module--headlineContentContainerHidden--8991d";
export var picture = "product-showcase-module--picture--3f7bf";
export var pictureSm = "product-showcase-module--pictureSm--4f395";
export var showLessArrow = "product-showcase-module--showLessArrow--bc53c";
export var subheadline = "product-showcase-module--subheadline--9215a";
export var wrapper = "product-showcase-module--wrapper--f3c05";
export var wrapperOuter = "product-showcase-module--wrapperOuter--46353";