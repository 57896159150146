//@ts-ignore
import React, { useRef } from 'react';
import { graphql } from 'gatsby';
import * as styles from './product-showcase.module.css';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import './swiper-card.css';
import {
    KontentRichText,
    KontentStringValue,
    KontentRadioMultiChoice,
    BrandColor,
    CTALocation,
    KontentMedia,
    KontentNumberValue,
} from '../../../types/KontentProps';
import { getColor, getMediaData, getMultiChoice } from '../Global/DataUtils';
import RichText from '../Global/RichText';
import CtaLink from '../Global/CtaLink';
import { BuildLinkHref } from '../Global/BuildLinkHref';
import { ProductCardsStackView } from './ProductShowcaseStackView';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';
import 'swiper/css/virtual';
import { Navigation, Pagination, Virtual, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import PlainTextElement from '../Global/Headlines';

interface Product {
    product_headline?: KontentStringValue;
    product_sub_headline?: KontentStringValue;
    product_description?: KontentRichText;
    desktop_image?: KontentMedia;
    mobile_image?: KontentMedia;
    headline_font_color?: BrandColor;
    product_headline_background_color?: BrandColor;
    cta_configuration__cta_label?: KontentStringValue;
    cta_configuration__cta_location_anchor_point?: KontentStringValue;
    cta_configuration__cta_location?: CTALocation;
    cta_configuration__cta_window_action?: KontentRadioMultiChoice<
        'new_window' | 'same_window'
    >;
}

interface ProductDetail {
    elements: Product;
}

interface Products {
    value: ProductDetail[];
}

interface Props {
    products: Products;
    mobile_view?: KontentRadioMultiChoice<'stacked' | 'carousel'>;
    number_to_show_on_mobile?: KontentNumberValue;
    enable_slideshow?: KontentRadioMultiChoice<'enable' | 'disable'>;
}

const ProductShowcase: React.FC<Props> = ({
    products,
    mobile_view,
    number_to_show_on_mobile,
    enable_slideshow,
}) => {
    const prevRef = useRef(null);
    const nextRef = useRef(null);
    const breakpoints = useBreakpoint();
    const isTabletView = breakpoints.sm && !breakpoints.l;
    const isMobileOrDesktop = !breakpoints.sm || breakpoints.l;

    const modifiedProducts = {
        value: products.value.map((cardDetail: ProductDetail) => {
            const card = cardDetail.elements;
            return {
                productHeadline: card?.product_headline?.value,
                productSubHeadline: card?.product_sub_headline?.value,
                productDesc: RichText(card?.product_description),
                productImgLg: getMediaData(card?.desktop_image),
                productImgSm: getMediaData(card?.mobile_image),
                headlineFontColor: getColor(card?.headline_font_color),
                productHealineBackgroundColor: getColor(
                    card?.product_headline_background_color
                ),
                ctaLabel: card?.cta_configuration__cta_label?.value,
                ctaAnchor:
                    card?.cta_configuration__cta_location_anchor_point?.value,
                ctaLocation: card?.cta_configuration__cta_location,
                ctaWindowAct:
                    getMultiChoice(
                        card?.cta_configuration__cta_window_action
                    ) === 'new_window'
                        ? '_new'
                        : '_self',
            };
        }),
    };
    const uniqueId = `swiper-${Math.random().toString(36)?.substr(2, 9)}`;
    const isMobileViewTypeStack = mobile_view?.value[0]?.codename == 'stacked';
    const enableSlideshow = enable_slideshow?.value[0]?.codename == 'enable';
    const noOfCardsToDisplayOnMobile = number_to_show_on_mobile?.value;
    const cardsLessthanOrEqualToThree = modifiedProducts?.value?.length <= 3;
    const modifiedProductsWithDuplicates = [
        ...modifiedProducts?.value,
        ...modifiedProducts?.value,
    ];
    const CardsToMap =
        cardsLessthanOrEqualToThree && enableSlideshow
            ? modifiedProductsWithDuplicates
            : modifiedProducts?.value;

    const ProductShowcaseCarouselView = () => {
        return (
            <div className={styles.containerWrapper}>
                <div className="containerB">
                    <div
                        className="swiper-button-prev prevCustom"
                        id={`swiper-prev-${uniqueId}`}
                        ref={prevRef}
                    ></div>
                    <Swiper
                        className="showcaseSwiper"
                        modules={[Virtual, Navigation, Pagination, Autoplay]}
                        centerInsufficientSlides
                        virtual
                        spaceBetween={10}
                        navigation={{
                            nextEl: `#swiper-next-${uniqueId}`,
                            prevEl: `#swiper-prev-${uniqueId}`,
                        }}
                        pagination={
                            cardsLessthanOrEqualToThree && enableSlideshow
                                ? false
                                : {
                                      type: 'bullets',
                                      enabled: true,
                                      clickable: true,
                                  }
                        }
                        scrollbar={{ draggable: true }}
                        autoplay={enableSlideshow ? { delay: 5000 } : false}
                        speed={2000}
                        loop={enableSlideshow ? true : false}
                    >
                        {modifiedProducts.value.length &&
                            CardsToMap.map((card, idx) => {
                                return (
                                    <SwiperSlide
                                        virtualIndex={idx}
                                        key={idx}
                                        className="slideCustom"
                                    >
                                        <div
                                            className={styles.card}
                                            style={{
                                                width: '100%',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: '100%',
                                                }}
                                                className={styles.cardInner}
                                            >
                                                <section
                                                    className={styles.wrapper}
                                                >
                                                    {breakpoints.l &&
                                                    card.productImgSm ? (
                                                        <picture
                                                            className={
                                                                styles.picture
                                                            }
                                                        >
                                                            {card.productImgSm && (
                                                                <source
                                                                    media="(max-width:992px)"
                                                                    srcSet={
                                                                        card
                                                                            .productImgSm
                                                                            .url
                                                                    }
                                                                />
                                                            )}
                                                            <img
                                                                src={
                                                                    card
                                                                        .productImgLg
                                                                        ?.url
                                                                }
                                                                alt={
                                                                    card
                                                                        .productImgLg
                                                                        ?.description
                                                                }
                                                            />
                                                        </picture>
                                                    ) : null}
                                                    {isTabletView &&
                                                    card.productImgSm ? (
                                                        <div
                                                            className={
                                                                styles.picture
                                                            }
                                                        >
                                                            <picture
                                                                className={
                                                                    styles.picture
                                                                }
                                                            >
                                                                <img
                                                                    src={
                                                                        card
                                                                            .productImgLg
                                                                            ?.url
                                                                    }
                                                                    alt={
                                                                        card
                                                                            .productImgLg
                                                                            ?.description
                                                                    }
                                                                />
                                                            </picture>
                                                            {!breakpoints.l &&
                                                                breakpoints.sm && (
                                                                    <div
                                                                        className={
                                                                            styles.headlineContentContainer
                                                                        }
                                                                        style={{
                                                                            color: card.headlineFontColor
                                                                                ? card.headlineFontColor
                                                                                : 'black',
                                                                            backgroundColor:
                                                                                card.productHealineBackgroundColor
                                                                                    ? card.productHealineBackgroundColor
                                                                                    : '#fdf2e9',
                                                                        }}
                                                                    >
                                                                        {!breakpoints.sm &&
                                                                        card.productImgSm ? (
                                                                            <div
                                                                                className={
                                                                                    styles.pictureSm
                                                                                }
                                                                            >
                                                                                <picture
                                                                                    className={
                                                                                        styles.picture
                                                                                    }
                                                                                >
                                                                                    {card.productImgSm && (
                                                                                        <source
                                                                                            media="(max-width:576px)"
                                                                                            srcSet={
                                                                                                card
                                                                                                    .productImgSm
                                                                                                    .url
                                                                                            }
                                                                                        />
                                                                                    )}
                                                                                    <img
                                                                                        src={
                                                                                            card
                                                                                                .productImgLg
                                                                                                ?.url
                                                                                        }
                                                                                        alt={
                                                                                            card
                                                                                                .productImgLg
                                                                                                ?.description
                                                                                        }
                                                                                    />
                                                                                </picture>
                                                                            </div>
                                                                        ) : null}

                                                                        <div>
                                                                            {card.productHeadline && (
                                                                                <h2
                                                                                    className={
                                                                                        styles.headline
                                                                                    }
                                                                                >
                                                                                    <PlainTextElement
                                                                                        text={{
                                                                                            value: card.productHeadline,
                                                                                        }}
                                                                                    />
                                                                                </h2>
                                                                            )}
                                                                            {card.productSubHeadline && (
                                                                                <p
                                                                                    className={
                                                                                        styles.subheadline
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        card.productSubHeadline
                                                                                    }
                                                                                </p>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                        </div>
                                                    ) : null}
                                                    {isTabletView && (
                                                        <div
                                                            className={
                                                                styles.headlineContentContainerHidden
                                                            }
                                                        >
                                                            {!breakpoints.sm &&
                                                            card.productImgSm ? (
                                                                <div
                                                                    className={
                                                                        styles.pictureSm
                                                                    }
                                                                >
                                                                    <picture
                                                                        className={
                                                                            styles.picture
                                                                        }
                                                                    >
                                                                        {card.productImgSm && (
                                                                            <source
                                                                                media="(max-width:576px)"
                                                                                srcSet={
                                                                                    card
                                                                                        .productImgSm
                                                                                        .url
                                                                                }
                                                                            />
                                                                        )}
                                                                        <img
                                                                            src={
                                                                                card
                                                                                    .productImgLg
                                                                                    ?.url
                                                                            }
                                                                            alt={
                                                                                card
                                                                                    .productImgLg
                                                                                    ?.description
                                                                            }
                                                                        />
                                                                    </picture>
                                                                </div>
                                                            ) : null}

                                                            <div>
                                                                {card.productHeadline && (
                                                                    <h2
                                                                        className={
                                                                            styles.headline
                                                                        }
                                                                        style={{
                                                                            color: 'transparent',
                                                                        }}
                                                                    >
                                                                        <PlainTextElement
                                                                            text={{
                                                                                value: card.productHeadline,
                                                                            }}
                                                                        />
                                                                    </h2>
                                                                )}
                                                                {card.productSubHeadline && (
                                                                    <p
                                                                        style={{
                                                                            color: 'transparent',
                                                                        }}
                                                                        className={
                                                                            styles.subheadline
                                                                        }
                                                                    >
                                                                        {
                                                                            card.productSubHeadline
                                                                        }
                                                                    </p>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                                    {isMobileOrDesktop && (
                                                        <div
                                                            className={
                                                                styles.headlineContentContainer
                                                            }
                                                            style={{
                                                                color: card.headlineFontColor
                                                                    ? card.headlineFontColor
                                                                    : 'black',
                                                                backgroundColor:
                                                                    card.productHealineBackgroundColor
                                                                        ? card.productHealineBackgroundColor
                                                                        : '#fdf2e9',
                                                            }}
                                                        >
                                                            {!breakpoints.sm &&
                                                            card.productImgSm ? (
                                                                <div
                                                                    className={
                                                                        styles.pictureSm
                                                                    }
                                                                >
                                                                    <picture
                                                                        className={
                                                                            styles.picture
                                                                        }
                                                                    >
                                                                        {card.productImgSm && (
                                                                            <source
                                                                                media="(max-width:576px)"
                                                                                srcSet={
                                                                                    card
                                                                                        .productImgSm
                                                                                        .url
                                                                                }
                                                                            />
                                                                        )}
                                                                        <img
                                                                            src={
                                                                                card
                                                                                    .productImgLg
                                                                                    ?.url
                                                                            }
                                                                            alt={
                                                                                card
                                                                                    .productImgLg
                                                                                    ?.description
                                                                            }
                                                                        />
                                                                    </picture>
                                                                </div>
                                                            ) : null}

                                                            <div>
                                                                {card.productHeadline && (
                                                                    <h2
                                                                        className={
                                                                            styles.headline
                                                                        }
                                                                    >
                                                                        <PlainTextElement
                                                                            text={{
                                                                                value: card.productHeadline,
                                                                            }}
                                                                        />
                                                                    </h2>
                                                                )}
                                                                {card.productSubHeadline && (
                                                                    <p
                                                                        className={
                                                                            styles.subheadline
                                                                        }
                                                                    >
                                                                        {
                                                                            card.productSubHeadline
                                                                        }
                                                                    </p>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div
                                                        className={
                                                            styles.descriptionContentContainer
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.descriptionContent
                                                            }
                                                        >
                                                            {card.productDesc && (
                                                                <div
                                                                    className={
                                                                        styles.descriptionCopy
                                                                    }
                                                                >
                                                                    {
                                                                        card.productDesc
                                                                    }
                                                                </div>
                                                            )}
                                                            <div
                                                                className={
                                                                    styles.ctaContainer
                                                                }
                                                            >
                                                                {card.ctaLabel && (
                                                                    <CtaLink
                                                                        href={
                                                                            card?.ctaAnchor
                                                                                ? BuildLinkHref(
                                                                                      card?.ctaLocation
                                                                                  ) +
                                                                                  '#' +
                                                                                  card?.ctaAnchor
                                                                                : BuildLinkHref(
                                                                                      card?.ctaLocation
                                                                                  )
                                                                        }
                                                                        target={
                                                                            card.ctaWindowAct
                                                                        }
                                                                    >
                                                                        {
                                                                            card.ctaLabel
                                                                        }
                                                                    </CtaLink>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                );
                            })}
                    </Swiper>
                    <div
                        className="swiper-button-next nextCustom"
                        id={`swiper-next-${uniqueId}`}
                        ref={nextRef}
                    ></div>
                </div>
            </div>
        );
    };

    return (
        <>
            {!breakpoints.sm && isMobileViewTypeStack ? (
                <ProductCardsStackView
                    noOfCardsToDisplayOnMobile={
                        noOfCardsToDisplayOnMobile
                            ? noOfCardsToDisplayOnMobile
                            : modifiedProducts.value.length > 3
                            ? 3
                            : modifiedProducts.value.length
                    }
                    modifiedProducts={modifiedProducts}
                    revealMoreText={''}
                />
            ) : (
                <ProductShowcaseCarouselView />
            )}
        </>
    );
};

export default ProductShowcase;

export const fragmentProductShowcase = graphql`
    fragment ProductShowcase on kontent_item_component___product_showcase {
        id
        system {
            codename
        }
        elements {
            mobile_view {
                value {
                    codename
                }
            }
            number_to_show_on_mobile {
                value
            }
            enable_slideshow {
                value {
                    codename
                }
            }
            products {
                value {
                    ... on kontent_item_content_item___product_showcase_item {
                        id
                        elements {
                            product_headline {
                                value
                            }
                            product_sub_headline {
                                value
                            }
                            desktop_image {
                                ...kontentImage
                            }
                            mobile_image {
                                ...kontentImage
                            }

                            product_description {
                                ...kontentRichText
                            }
                            product_headline_background_color {
                                ...kontentColor
                            }
                            headline_font_color {
                                ...kontentColor
                            }
                            cta_configuration__cta_window_action {
                                value {
                                    codename
                                }
                            }
                            cta_configuration__cta_location_anchor_point {
                                value
                            }
                            cta_configuration__cta_location {
                                ...kontentCtaLocation
                            }
                            cta_configuration__cta_label {
                                value
                            }
                        }
                    }
                }
            }
        }
    }
`;
