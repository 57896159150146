import React, { useRef, useState } from 'react';
//@ts-ignore
import * as styles from './product-showcase.module.css';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import CtaLink from '../Global/CtaLink';
import { BuildLinkHref } from '../Global/BuildLinkHref';
import ShowMoreArrow from '../Global/ShowMoreArrow';
import PlainTextElement from '../Global/Headlines';

interface Props {
    noOfCardsToDisplayOnMobile?: number;
    modifiedProducts?: any;
    revealMoreText?: string;
}

export const ProductCardsStackView: React.FC<Props> = ({
    noOfCardsToDisplayOnMobile,
    modifiedProducts,
}) => {
    const wrapperRef = useRef(null);
    const breakpoints = useBreakpoint();
    const [showAll, setShowAll] = useState(
        noOfCardsToDisplayOnMobile && !breakpoints.sm ? false : true
    );
    const toggleShowAll = () => {
        setShowAll((prevShowAll) => !prevShowAll);
    };

    return (
        <>
            <div className={styles.wrapperOuter}>
                <div className={styles.wrapper} ref={wrapperRef}>
                    {modifiedProducts.value.length &&
                        modifiedProducts.value
                            .slice(
                                0,
                                breakpoints.sm || (showAll && !breakpoints.sm)
                                    ? modifiedProducts.value.length
                                    : noOfCardsToDisplayOnMobile
                            )
                            .map((card, idx) => {
                                return (
                                    <div
                                        className={styles.cardWrapperStack}
                                        key={idx}
                                    >
                                        <div
                                            className={
                                                styles.headlineContentContainer
                                            }
                                            style={{
                                                color: card.headlineFontColor
                                                    ? card.headlineFontColor
                                                    : 'black',
                                                backgroundColor:
                                                    card.productHealineBackgroundColor
                                                        ? card.productHealineBackgroundColor
                                                        : '#fdf2e9',
                                            }}
                                        >
                                            {!breakpoints.sm &&
                                            card.productImgSm ? (
                                                <div
                                                    className={styles.pictureSm}
                                                >
                                                    <picture
                                                        className={
                                                            styles.picture
                                                        }
                                                    >
                                                        {card.productImgSm && (
                                                            <source
                                                                media="(max-width:576px)"
                                                                srcSet={
                                                                    card
                                                                        .productImgSm
                                                                        .url
                                                                }
                                                            />
                                                        )}
                                                        <img
                                                            src={
                                                                card
                                                                    .productImgLg
                                                                    ?.url
                                                            }
                                                            alt={
                                                                card
                                                                    .productImgLg
                                                                    ?.description
                                                            }
                                                        />
                                                    </picture>
                                                </div>
                                            ) : null}
                                            {card.productHeadline && (
                                                <h2 className={styles.headline}>
                                                    <PlainTextElement
                                                        text={{
                                                            value: card.productHeadline,
                                                        }}
                                                    />
                                                </h2>
                                            )}
                                            {card.productSubHeadline && (
                                                <p
                                                    className={
                                                        styles.subheadline
                                                    }
                                                >
                                                    {card.productSubHeadline}
                                                </p>
                                            )}
                                        </div>
                                        <div
                                            className={
                                                styles.descriptionContentContainer
                                            }
                                        >
                                            <div
                                                className={
                                                    styles.descriptionContent
                                                }
                                            >
                                                {card.productDesc && (
                                                    <div
                                                        className={
                                                            styles.descriptionCopy
                                                        }
                                                    >
                                                        {card.productDesc}
                                                    </div>
                                                )}
                                                <div
                                                    className={
                                                        styles.ctaContainer
                                                    }
                                                >
                                                    {card.ctaLabel && (
                                                        <CtaLink
                                                            href={
                                                                card?.ctaAnchor
                                                                    ? BuildLinkHref(
                                                                          card?.ctaLocation
                                                                      ) +
                                                                      '#' +
                                                                      card?.ctaAnchor
                                                                    : BuildLinkHref(
                                                                          card?.ctaLocation
                                                                      )
                                                            }
                                                            target={
                                                                card.ctaWindowAct
                                                            }
                                                        >
                                                            {card.ctaLabel}
                                                        </CtaLink>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                    {noOfCardsToDisplayOnMobile &&
                        modifiedProducts.value.length >
                            noOfCardsToDisplayOnMobile &&
                        !breakpoints.sm && (
                            <>
                                {showAll ? (
                                    <div className={styles.showLessArrow}>
                                        <ShowMoreArrow
                                            btnText={''}
                                            strokeColor={'#505251'}
                                            handleClick={() => {
                                                toggleShowAll();
                                                if (wrapperRef.current) {
                                                    wrapperRef.current.scrollIntoView(
                                                        {
                                                            behavior: 'instant',
                                                        }
                                                    );
                                                }
                                            }}
                                            transform={'0'}
                                        />
                                    </div>
                                ) : (
                                    <ShowMoreArrow
                                        btnText={''}
                                        strokeColor={'#505251'}
                                        handleClick={toggleShowAll}
                                        transform={'0'}
                                    />
                                )}
                            </>
                        )}
                </div>
            </div>
        </>
    );
};
